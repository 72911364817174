.c-pagination {
    overflow-x: auto;
}

.c-pagination a {
    padding: 19px 8px;
    list-style-type: none;

}
.c-pagination li {
    padding: 4px 8px;

    list-style-type: none;

}

/* pagination  */
.paginationBttns {
    padding-left: 0px;
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 4px 8px;
}

.eachpage {
    border: 1px solid rgb(238, 238, 238);
    font-family: MuseoSansRounded500;
    cursor: pointer;
    /* padding: 4px 8px; */

}

.eachpage:hover,
.previousBttn:hover,
.nextBttn:hover {
    cursor: pointer;
    background-color: rgb(228, 227, 227);
    /* padding: 4px 8px; */


}

.previousBttn,
.nextBttn,
.paginationActive {
    border: 1px solid rgb(238, 238, 238);
    /* padding: 4px 8px; */
    background-color: white;
}

.previousBttn,
.nextBttn {
    border-radius: 10px;
}

.paginationActive {
    color: white;
    background-color: #ffcb00;
}

.paginationDisabled {
    background-color: white;
}