/* admin login  */

.auth-background {
     background: transparent linear-gradient(0deg, #0c3a3e00 0%, #ffcb00 100%) 0% 0% no-repeat padding-box;
     background-size: 100% 100%;
     
}
.auth-background >div{
    box-shadow:10p 10px 3px #33333348 ;
    border-radius: 50px 10px 50px 10px;
}

.auth-background2 {
    background-color: #33333348;

}

/* dashboard  */
.icon-width {
    width: 40px;
    display: inline-block;
}

.active-icon-color path {
    fill: #fff !important;
}

.inactive-icon-color path {
    fill: #888c8b !important;
}

.active-icon-color {
    fill: #fff !important;
}

.inactive-icon-color {
    fill: #888c8b !important;
}

.icon-box {
    height: 40px;
    width: 40px;
}

/* Admin - User manager CSS */
.break-line {
    background-color: grey;
    opacity: 0.3;
    height: 1px;
}

.btn-active {
    border-radius: 14px;
    color: #099d14;
    background-color: #6de2773a;
}

.footer {
    font-size: small;
    font-weight: 500;
    color: black;
}

/*css for resolved,transferred and active buttons are same */
/* css for inactive, success and open are same */
/* css for pending and processing are same */
.btn-open {
    border-radius: 30px;
    color: #ff5757;
    background-color: #ff57571a;
    height: 39px;
}

.btn-close {
    border-radius: 30px;
    color: #099d14;
    background-color: #6de2773a;
    height: 39px;
}

.quote-card {
    border-radius: 8px;
    border: 1px solid #eaeaea;
}

.plan {
    font-size: 24px;
    color: #434943;
    font-family: Fredoka One;
}

.btn-pending {
    border-radius: 14px;
    color: #20bef8;
    background-color: #20BEF81A;
}

.card-carousel img {
    border-radius: 10px;
    /* height: 230px;
        width: 230px; */
}

.card-carousel {
    border-radius: 10px;
}

.card-carousel span {
    font-weight: 900;
}

.stx {
    color: #099d14;
    background-color: #6de2773a;
    border-radius: 4px;
    padding: 3px !important;
    font-size: x-small;
}

.stx2 {
    color: #FF5757;
    background-color: #f8b0b08a;
    border-radius: 4px;
    padding: 3px !important;
    font-size: x-small;
}

.payout {
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    padding: 18px !important;
    margin-right: 4px;
    overflow: auto;

}

.pay {
    padding: 4px !important;
}

.banner-box-settings {
    background-color: #f5ca31;
    box-shadow: #EAB900;
    border-radius: 20px !important;
    box-shadow: 0px 8px 0px 0px rgba(247, 145, 0, 0.68);
    -webkit-box-shadow: 0px 14px 0px -4px rgba(247, 145, 0, 0.68);
    -moz-box-shadow: 0px 14px 0px 0px rgba(247, 145, 0, 0.68);
    /* box-shadow-bottom-right-radius: 80px; 
        box-shadow-width: 80px; */

}

.home {
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    padding: 18px !important;

}

.home2 {
    padding: 8px !important;
}

.yr {
    font-weight: 800 !important;
}

.fa-star {
    font-size: x-small;
    padding-left: 1px !important;
    color: #C3C3C3;
}

.fa-star:hover {
    color: #FFCA00;

}

.boxShadow {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.boxShadow hr {
    width: 90%;
    height: 1px;
    margin-left: 15px !important;

    margin-bottom: 18px !important;

    background-color: #EBF2F7;

}

.stats1 {
    color: #089D12 !important;
    display: block !important;
    margin: auto !important;

}

.stats2 {
    color: #FF5757 !important;
    display: block !important;
    margin: auto !important;

}

.notify-detail {
    padding: 50px !important;
}

.btn-read {
    color: #099d14 !important;
    background-color: #6de2773a !important;
    padding: 5px 18px !important;
    border: none;
}

.date {
    padding-top: 25px !important;
}

.profile-bg {
    background-color: #F4F6FB !important;
    padding: 20px !important;
    margin-top: 20px !important;

    border-radius: 20px;
}

.btn-edit {
    color: white !important;
    background-color: #58CC24 !important;
    padding: 8px 18px !important;
    box-shadow: 0px 4px 0px 0px #47a021;
    -webkit-box-shadow: 0px 4px 0px 0px #47a021;
    -moz-box-shadow: 0px 4px 0px 0px #47a021;
    border: none !important;
    font-size: 14px !important;
    font-family: "Fredoka One", cursive;
    border-radius: 15px;
    letter-spacing: 0.84px;
    height: 49px;

}
.edit-profile .edit-input{
     margin-top: 10px !important;
}
.edit-profile .edit-input input {
    width: 100%;
    padding: 10px 10px !important;
    border: none;
    border: 1px solid #EAEAEA !important;
    background-color: #F9F9F9;
    border-radius: 8px !important;
}

.edit-profile .edit-input select {
    width: 100%;
    padding: 10px 10px !important;
    border: none;
    border: 1px solid #EAEAEA !important;
    background-color: #F9F9F9;
    border-radius: 8px !important;
}

.edit-profile button {
    margin-top: 18px !important;
}

.edit-profile img {
    margin-top: 18px !important;
}

.border-dot {
    border: 1px dotted rgb(175, 174, 174);
}


.btn-replace {
    background-color: #089D12;
    padding: 5px 18px !important;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    color: white;
}

.btn-update,
.btn-apply {
    color: white !important;
    background-color: #20BEF8 !important;
    padding: 6px 15px !important;
    box-shadow: 0px 4px 0px 0px #1CAEE3;
    -webkit-box-shadow: 0px 4px 0px 0px #1CAEE3;
    -moz-box-shadow: 0px 4px 0px 0px #1CAEE3;
    font-size: 14px !important;
    font-family: "Fredoka One", cursive !important;
    border-radius: 15px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    height: 49px;

}

.btn-green {
    color: white !important;
    background-color: #58cc24 !important;
    padding: 6px 15px !important;
    box-shadow: 0 4px 2px #6a9b6e !important;
    -webkit-box-shadow: 0px 4px 0px 0px #6a9b6e;
    -moz-box-shadow: 0px 4px 0px 0px #6a9b6e;
    font-size: 14px !important;
    font-family: "Fredoka One", cursive !important;
    border-radius: 15px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    height: 49px;

}

.btn-apply {
    padding: 5px 20px !important;
}

.btn-clear {
    color: white !important;
    background-color: #FF5757 !important;
    padding: 6px 15px !important;
    box-shadow: 0px 4px 0px 0px #a83636;
    -webkit-box-shadow: 0px 4px 0px 0px #a83636;
    -moz-box-shadow: 0px 4px 0px 0px #a83636;
    font-size: 14px !important;
    font-family: "Fredoka One", cursive !important;
    border-radius: 15px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    height: 49px;
    /* box-shadow: none !important; */
}

.btn-back {
    color: white !important;
    background-color: #089D12 !important;
    font-size: 14px !important;
    font-family: "MuseoSansRounded900", regular;
    border-radius: 10px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    height: 39px;
    width: 93px;
}

button:focus {
    border: 1px;
}

input:focus {
    border: 1px solid #1670BE;
    box-shadow: 0 0 3px #1670BE;
    outline-offset: 0px;
    outline: none;
}

input[type="text"],
input[type="password"],
textarea,
select {
    outline: none !important;
}


.filter-cb input[type="checkbox"] {
    background: #FFF;
    border-color: #EAEAEA;
    line-height: 14px !important;
    height: 16px;
    width: 16px;

}

.filter-cb input[type="checkbox"]:checked {
    color: #089D12 !important;
}


.edit-profile select {
    width: 280px;
    /* border: none; */
    padding: 5px 8px !important;
    font-size: 14px;

}

.edit-profile .headings {
    margin-top: 18px !important;
    font-size: 24px;
}

.boxes {
    border: 1px solid #575e57;
    padding: 1px 6px !important;
    font-size: 14px;
    border-radius: 5px;

}

.new {
    margin-bottom: 7px !important;
}

.read-more-text {
    display: none;
}

.read-more-text--show {
    display: inline;
}

.btn-view {
    background-color: #089D12;
    border-radius: 10px !important;
    color: white;
    font-size: 14px !important;
    font-family: "MuseoSansRounded900" !important;

}

.btn-remove {
    background-color: #FF5757;
    border-radius: 15px;
    color: white;
    padding: 6px 15px !important;
    box-shadow: 0px 4px 0px 0px #D94B4B !important;
    -webkit-box-shadow: 0px 4px 0px 0px #D94B4B !important;
    -moz-box-shadow: 0px 4px 0px 0px #D94B4B !important;
    font-size: 14px !important;
    font-family: "Fredoka One", cursive;
    height: 49px;
    letter-spacing: 0.84px;

}

.hrtag hr {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.add-course-select {
    width: 100%;
    /* margin-top: 18px !important; */
    padding: 10px 10px !important;
    border: none;
    border: 1px solid #EAEAEA !important;
    background-color: #F9F9F9;
    border-radius: 8px !important;
}


.check-out {
    width: 24px;
    height: 24px;
    background: var(--unnamed-color-f9f9f9) 0% 0% no-repeat padding-box;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 2px solid #159D18;
    border-radius: 5px;
    opacity: 1;
}

.check-incomplete-out {
    width: 24px;
    height: 24px;
    background: var(--unnamed-color-f9f9f9) 0% 0% no-repeat padding-box;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 2px solid #EAEAEA;
    border-radius: 5px;
    opacity: 1;
}

.check-tik {
    color: #159D18;
    width: 13px;
    height: 10px;
}



select {
    color: gray !important;
}

.paddings i {
    padding: 10px 18px !important;

}

.paddings {
    border-radius: 5px;
}

.text-area {
    border-radius: 15px;
    background-color: #F9F9F9;
    border: 1px solid #EAEAEA;
    padding: 10px !important;
    color: #C3C3C3;
}

.img_wrp {
    display: inline-block;
    position: relative;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
}


/* search icon within input css */
.input-icons i {
    position: absolute;
}

.input-icons {
    width: 100%;
    margin-bottom: 6px;
}

.search {
    padding: 8px 15px !important;
    min-width: 40px;
    color: gray;
}

.btn-dark {
    background-color: #041D39;
    padding: 4px 10px;
}

.genCont {
    width: 80%;
    margin: auto !important;
    padding-left: 180px !important;
    padding-right: 180px !important;
}

.about {
    padding: 20px !important;
}

.chat {
    padding-top: 530px !important;
}

input[type="file"] {

    width: 100%;
    outline: none !important;
}