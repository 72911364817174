* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 1200px;
}



body>iframe {
  pointer-events: none;
}

.tx-yellow {
  color: #ffcb00;
}

.bg-yellow {
  background-color: #ffcb00;
}

.tx-green {
  color: #089D12;
}

.tx-error {
  color: crimson;
}

.tx-lt-black {
  color: #1B221B;
}

.tx-lt-black-op-5 {
  color: #1B221B;
  opacity: 0.5;
}

.tx-red {
  color: #f10d0dd0;
}

.bg-green {
  background-color: #089D12;
}

.bg-sky {
  background-color: #20bef8;
}

.tx-sky {
  color: #20bef8;
}

.tx-grey {
  color: #C3C3C3;
}

.tx-lt-grey {
  color: #434943;
}

.sub-heading {
  color: #434943;
  font-family: "MuseoSansRounded700", regular;
  font-size: 14px;
}

.tx-black {
  color: black;
}

.bg-teal {
  background-color: #dee4e6;
}

.h-85 {
  height: 85%;
}

.b-grey {
  border: 1px solid rgb(231, 227, 227);
  border-radius: 10px;
}

.box-shadow-grey {
  box-shadow: 0px 0 15px 3px rgba(235, 229, 229, 0.678);
}


/* button  */
.btn-green {
  background-color: #099d14;
  ;
  color: white;
  border-radius: 10px;
}

/* fontsize  */
.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-11 {
  font-size: 11px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-42 {
  font-size: 42px;
}

.fs-45 {
  font-size: 45px;
}

.fs-61 {
  font-size: 61px;
}

.fs-31 {
  font-size: 31px;
}

.lh-26 {
  line-height: 26px;
}

.lh-21 {
  line-height: 21px;
}

.lh-30 {
  line-height: 30px;
}

.lh-18 {
  line-height: 18px;
}

.lh-15 {
  line-height: 15px;
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.f-robo {
  font-family: "Roboto", sans-serif;
}

.f-fred {
  font-family: "Fredoka One", cursive;
}

.f-lily {
  font-family: 'Lilita One', cursive;
}

.bold {
  font-weight: bold;
}

p {
  margin: 0;
  padding: 0;
}

.link {
  color: green !important;
  font-family: MuseoSansRounded700;
}

.link:hover {
  border-bottom: 1px dotted green;
}

.overflow-elipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.br-2 {
  border-radius: 20px;
}

.br-1 {
  border-radius: 10px;
}

.btn {
  /* box-shadow: none !important; */
  transition: 0.6s;
}

.btn:active {
  transform: scale(0.95);
}

.ls {
  letter-spacing: 0.96px
}

/* position  */
.p-rel {
  position: relative;
}

.p-abs {
  position: absolute;
}

.p-sticky {
  position: sticky;
}

.p-fix {
  position: fixed;
}

.tp-0 {
  top: 0;
}

.bt-0 {
  bottom: 0;
}

.lt-0 {
  left: 0;
}

.rt-0 {
  right: 0;
}

.all-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.br {
  border: 2px solid red;
}

.wh-40{
  width: 40px;
  height: 40px;
}

/* z-index  */
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.pointer {
  cursor: pointer;
}

.clear {
  clear: both;
}

.wrap {
  white-space: pre-wrap !important;
}

/* text-decoration  */
.decoration-line {
  text-decoration: underline;
}

.decoration-inline {
  text-decoration: line-through;
}

.decoration-none {
  text-decoration: none;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

/* //Radio  */
.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
  transition: 0.4s;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: rgb(177, 176, 176);
  transition: 0.4s;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #099d14;
  ;
  background-color: transparent;
}

.radio-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: #099d14;
  ;
}

.radio-item input[type=radio]:checked+label {
  color: rgb(58, 57, 57);
}

.c-dropdown-body {
  box-shadow: 0 0 10px 3px rgba(204, 202, 202, 0.575);
  display: none;
  transition: 0.5s;
  z-index: 2;
  bottom: 25px;
  left: 30px;
}

.c-dropdown-body ul li {
  margin: 0px !important;
  padding: 5px 10px !important;
  transition: 0.3s;
}

.c-dropdown-body ul li label {
  cursor: pointer;
  color: #434943b6;
}

.c-dropdown-body ul li:hover {
  background-color: #d4d9db96;
  /* color: #adb2b4ed; */
}

.c-drop-icon:checked~.c-dropdown-body {
  display: initial;
}

.border-sky {
  border: 3px solid green;
}

.border-yellow {
  border: 2px solid #ffcb00;
}

/* margins  */
.cmx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.cmy-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cmx-4 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.cmy-4 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.cpx-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cpy-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.cpx-4 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.cpy-4 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* Grid view */
.grid-view {
  overflow-x: auto;
width: 100%;
}

.grid-view .grid-card {
  width: 1300px;
}

.grid-view {
  --scrollbarBG: #F1F3F7;
  --thumbBG: #90A4AE;
}

.grid-view::-webkit-scrollbar {
  width: 10px !important;
}

.grid-view {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.grid-view::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.grid-view::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

/* form box */
.form-box {
  margin-bottom: 10px;
}

.form-box label {
  font-family: "MuseoSansRounded500", regular;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.form-box .input-box {
  width: 100%;
  height: 49px;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  border: none;
  border: 1px solid grey;
  font-family: "MuseoSansRounded700", regular;
}

@media (max-width: 600px) {

  .cmx-2,
  .cmx-4 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .cmy-2,
  .cmy-4 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .cpx-2,
  .cpx-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .cpy-2,
  .cy-4 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.faded {
  z-index: 3;
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: rgba(107, 107, 107, 0.863);
  top: 0;
  right: 0;
}

.centerpop-up {
  z-index: 3;
  position: absolute;
  /* top: 25%;
    right: 33%; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto !important;
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  height: 55%;
  width: 35%;
  color: black;
}

.btn-popup {
  border-radius: 7px !important;
  color: white !important;
  background-color: #20BEF8 !important;
  padding: 6px 55px !important;
  box-shadow: 0px 4px 0px 0px #1687b1;
  -webkit-box-shadow: 0px 4px 0px 0px #1687b1;
  -moz-box-shadow: 0px 4px 0px 0px #1687b1;


}

.date {
  padding: 10px !important;
  min-width: 40px !important;
}



.DateInput_input {
  font-weight: 200;
  font-size: 14px !important;
  line-height: 12px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 3px 5px !important;
}

.DateInput {
  height: 100%;
  padding: 4px !important;


}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {

  background-color: #20bef8;
  border: 1px double #20bef8;
  color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #20bef8;
  border: 1px double #20bef8;
  color: white;
}

.CalendarDay__selected_span {
  background-color: #20bef8;
  border: 1px double #20bef8;
  color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #20bef8a4;
  border: 1px double #20bef8a9;
  color: #fff
}

/* ---------------------------  */
/* course  */
.course-img img {
  transition: 1s;
}

.course-img img:hover {
  transform: scale(1.1);
}

.hover-shadow {
  transition: 0.6s;
  padding: 8px !important;
}

.hover-shadow:hover {
  transition: 0.6s;
  border-radius: 100%;
  background-color: rgb(238, 225, 225);
}

.apexcharts-toolbar {
  z-index: 0 !important;
}