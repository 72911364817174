@font-face {
    font-family: "MuseoSansRounded100";
    src: local("MuseoSansRounded100"),
    url("../fonts/MuseoSansRounded100.woff") format("truetype");
    }
    @font-face {
    font-family: "MuseoSansRounded300";
    src: local("MuseoSansRounded300"),
    url("../fonts/MuseoSansRounded300.woff") format("truetype");
    }
    @font-face {
    font-family: "MuseoSansRounded500";
    src: local("MuseoSansRounded500"),
    url("../fonts/MuseoSansRounded500.woff") format("truetype");
    }
    @font-face {
    font-family: "MuseoSansRounded700";
    src: local("MuseoSansRounded700"),
    url("../fonts/MuseoSansRounded700.woff") format("truetype");
    }
    @font-face {
        font-family: "MuseoSansRounded900";
        src: local("MuseoSansRounded900"),
        url("../fonts/MuseoSansRounded900.woff") format("truetype");
        }
    @font-face {
        font-family: "MuseoSansRounded1000";
        src: local("MuseoSansRounded1000"),
            url("../fonts/MuseoSansRounded1000.woff") format("truetype");
    }
    .ms-100{
        font-family:"MuseoSansRounded100",regular ;
    }
    .ms-300{
        font-family:"MuseoSansRounded300",regular ;
    }
    .ms-500{
        font-family:"MuseoSansRounded500",regular ;
    }
    .ms-700{
        font-family:"MuseoSansRounded700",regular ;
    }
    .ms-900{
        font-family:"MuseoSansRounded900",regular ;
    }
    .ms-1000{
        font-family:"MuseoSansRounded1000",regular ;
    }

img{
    max-width: 100% !important;
}