.popup-modal {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    /* left: 0; */
    right: 0;
    z-index: 5;
    background-color: rgba(114, 114, 114, 0.4)
  }
  .popup-modal-body {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 4px rgb(235, 233, 233);
    min-height: 20%;
    max-height: 90%;
    overflow-y: auto;
  }
  .left-popup{
    background-color: white;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 4px rgb(235, 233, 233);
    width:400px;
    height: 100vh;
    overflow-y: auto;
  }
  .right-popup{
    background-color: white;
    border-radius: 20px 0 0 20px;
    box-shadow: 0 0 4px rgb(235, 233, 233);
    width:40%;
    height: 100vh !important;
    overflow-y: auto;
  }
  .popup-modal .popup-modal-body.xsm {
    width: 20%;
  }
  .popup-modal .popup-modal-body.sm {
    width: 30%;
  }
  .popup-modal .popup-modal-body.md {
    width: 60%;
  }
  .popup-modal .popup-modal-body.lg {
    width: 80%;
  }
  .popup-modal .popup-modal-body.xlg {
    width: 90%;
  }
 
  @media (max-width: 850px) {
    .popup-modal .popup-modal-body.xsm {
      width: 80%;
    }
    .popup-modal .popup-modal-body.sm {
      width: 80%;
    }
    .popup-modal .popup-modal-body.md {
      width: 80%;
    }
    .popup-modal .popup-modal-body.lg {
      width: 80%;
    }
    .popup-modal .popup-modal-body.xlg {
      width: 80%;
    }
    .popup-modal h2 {
      font-size: 16px;
    }
    .popup-modal h3 {
      font-size: 20px;
    }
  } 
  @media (max-width: 600px) {
    .popup-modal .popup-modal-body.xsm {
      width: 100%;
    }
    .popup-modal .popup-modal-body.sm {
      width: 100%;
    }
    .popup-modal .popup-modal-body.md {
      width: 100%;
    }
    .popup-modal .popup-modal-body.lg {
      width: 100%;
    }
    .popup-modal .popup-modal-body.xlg {
      width: 100%;
    }
    .popup-modal h2 {
      font-size: 16px;
    }
    .popup-modal h3 {
      font-size: 20px;
    }
    .left-popup{
      width:100%;
    }
    .right-popup{
      width: 100%;
    }
  }
  