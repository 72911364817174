.userdash {
    height: 100vh;
    color: #1B221B;
}

.left-icon-sm-strip {
    background-color: #20bef8;
}

.left-icon-sm-strip i {
    color: white;
}

.left-dash .overlay-text {
    display: none;
    background-color: #ffcb00;
    z-index: 2;
    color: white;
    border-radius: 5px;
    font-family: "MuseoSansRounded500", regular;
    font-size: 14px;
    transition: 0.3s;
    left: 40px;
}

.overlay-cover:hover>.overlay-text {
    display: initial;

}

.userdash .left-dash ul li {
    margin: 10px 0px !important;
    padding: 5px 0px !important;
    padding-left: 10px !important;
    list-style: none;
    border-radius: 10px;
    cursor: pointer;
}

.userdash .left-icon-lg-strip ul li p {
    color: #1b221b;
    opacity: 0.5;

}

.userdash .left-dash .active-tab p {
    color: white;
    opacity: 1;
}

.userdash .left-dash .active-tab {
    color: white;
    background-color: #099d14 !important;
}

.userdash .left-dash .active-tab-sm {
    color: white;
    background-color: #ffcb00 !important;
}

.userdash .icon-border {
    border: 2px solid rgb(240, 236, 236);
    border-radius: 5px;
}

.userdash .icon-border:hover {
    border: 2px solid #20bef8;
    ;
}

.userdash {
    height: 100vh;
}

.userdash .left-dash {
    height: 100vh;
    overflow: auto;
}

.userdash .right-dash {
    height: 100vh;
    overflow: auto;
}

/*  Menu  */
.userdash .menu ul li {
    margin: 10px 0px !important;
    padding: 5px 0px !important;
    padding-left: 10px !important;
    color: black;
    list-style: none;
    border-radius: 10px;
    cursor: pointer;
}

.userdash .menu .menu-top {
    height: 40px;
    width: 60%;
    margin: 0 auto !important;
    background-color: #099d14;
    border-radius: 0 0 250px 250px;
}

.userdash .menu .active-tab {
    color: white;
    background-color: #099d14 !important;
}

/* tutor home  */
.user-home .banner-box {
    height: 270px;
    width: 100%;
    background-color: #ffcb00;
    border-radius: 20px;
}

.user-home .banner-bottom {
    height: 10px;
    width: 90%;
    border-radius: 0 0 25px 25px;
    background-color: #e0b402;
}

.user-home .analytic-data {
    border-radius: 10px;
    border: 2px solid rgb(240, 237, 237);
}

.alert-class {
    background-color: #ff5757;
    padding: 10px 20px !important;
    border-radius: 10px;
}

.btn-new-notify {
    background-color: #ffcb00;
    border-radius: 20px;
}

.btn-detail {
    background-color: #20bef8;
    border-radius: 10px;
    color: white;
    height: 39px;
    font-size: 14px !important;
}

.btn-read {
    background-color: #d3d9db;
    border-radius: 20px;
}

.btn-unread {
    color: #0fa5db !important;
    background-color: #cce7f1;
    border-radius: 20px;
}

.border-orange {
    border: 1px solid #ffcb00;
    border-radius: 10px;
}

.userdash .notify-card:hover {
    border: 1px solid #20bef8;
    cursor: pointer;
}


/* My Profile  */
.userdash .my-address {
    background-color: #f4f6fb;
}

.userdash .border-dot {
    border: 1px dotted rgb(175, 174, 174);
}

/* calendar  */
.cl-body {
    background-color: #f4f6fb !important;
    border: none !important;
    padding: 10px 0 !important;
}

.tilename {
    padding: 10px !important;
    background-color: #f4f6fb !important;
}

.reshedule-btn {
    background-color: rgb(241, 226, 229);
    color: rgb(240, 44, 83);
    border-radius: 4px;
}

.returnCall-btn {
    background-color: #ccccff;
    color: rgb(240, 44, 83);
    border-radius: 4px;
}

/* library  */
.tutor-library .input-box {
    border: none;
    box-shadow: 0px 5px 20px #00000014;
    outline: none;
    border-radius: 10px;
    height: 45px !important;
    border: 2px solid #EAEAEA;

}

.tutor-library .course-card:hover {
    border: 1px solid green;
}

/* My student  */
.userdash .chat-student-card:hover {
    border: 1px solid #20bef8;
    cursor: pointer;
}

.btn-report {
    border-radius: 13px;
    background-color: crimson;
    box-shadow: 0 4px 2px rgb(190, 52, 80);
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 6px 10px !important;
}


/* Support  */
.user-support .banner-box-support {
    height: 270px;
    width: 100%;
    background-color: #20bef8;
    border-radius: 20px;
}

.user-support .banner-bottom-support {
    height: 10px;
    width: 90%;
    border-radius: 0 0 25px 25px;
    background-color: #169fd1;
}

/* Faqs  */
.userdash .back-btn {
    border-radius: 6px;
    background-color: #58cc24;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;

}

/* switch  */
.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}

.userdash .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.userdash .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.userdash .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.userdash input:checked+.slider {
    background-color: #099d14;
}

.userdash input:focus+.slider {
    box-shadow: 0 0 1px #099d14;
}

.userdash input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded sliders */
.userdash .slider.round {
    border-radius: 10px;
}

.userdash .slider.round:before {
    border-radius: 50%;
}





/* switch complete  */
@media (max-width:600px) {
    .userdash .left-dash {
        height: 100%;
    }

    .userdash {
        height: 100%;
    }

    .userdash .right-dash {
        height: 100%;
    }

    .user-home .banner-box {
        height: auto;
    }

    .tutor-support .banner-box-support {
        height: auto;
    }
}

/* .userdash .left-dash::-webkit-scrollbar {display:none;} */


body::-webkit-scrollbar {
    width: 10px !important;
}

.left-dash::-webkit-scrollbar {
    scrollbar-width: thin !important;
    scrollbar-color: #90A4AE #90A4AE !important;
}

.left-dash::-webkit-scrollbar::-webkit-scrollbar-track {
    background: var(--scrollbarBG) !important;
}

.left-dash::-webkit-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) !important;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}