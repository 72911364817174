.three-dot-body{
    box-shadow: 0 0 10px 3px rgba(204, 202, 202, 0.575);
    transition: 0.5s;
    z-index: 2;
    bottom:20px;
    right: 20px;
    width: 150px;
    background-color: white !important;
    height: auto;
    display: none;
  }
  .three-dot-body ul li{
   margin: 0px !important;
   padding: 5px 10px !important;
   transition: 0.3s;
   cursor: pointer;
  }
  .three-dot-body ul li label{
    cursor: pointer;
    color: #434943b6;
  }
  .three-dot-body ul li:hover{
   background-color: #d4d9db96;
   color: #adb2b4ed;
   }
